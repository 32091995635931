import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';

// importing Recoil Provider for State Management
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

// Fingerprint
// import Fingerprint from 'components/Fingerprint';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // onError: (error, query) => {
    //   // 🎉 only show error toasts if we already have data in the cache
    //   // which indicates a failed background update
    //   if (query.state.data !== undefined) {
    //     toast.error(`Sorry, an error has occurred, please try again or contact the administrator if the problem persists.`);
    //   }
    // }
  })
});

function Provider({ children }) {
  // Initialize JudoPay Web SDK
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://web.judopay.com/js/0.0/judopay.min.js';
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  return (
    <QueryClientProvider client={queryClient} contextSharing>
      <RecoilRoot>
        <RecoilNexus />
        {children}
        {/* <Fingerprint /> */}
      </RecoilRoot>
      <ToastContainer />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

Provider.propTypes = {
  children: PropTypes.any
};

export default Provider;
